h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0 !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
}
.table-report th,
.table-report td {
    padding: 0.25rem !important;
    border: 1px solid #080808 !important;
}


$image-bg: gray;
$image-border: white;
$image-border-current: aqua;

.image {
    opacity: 0.8;
    width: 200px;
    height: 160px;
    background-position: center center;
    background-color: $image-bg;
    display: inline-block;
    margin: 10px;

    &:hover {
      opacity: 1;
    }
}

.radio-img {
    > input { 
        display:none;
    }
    > .image{
  cursor:pointer;
      border: 2px solid black;

}
    > input:checked + .image{ 
  border:2px solid orange;
}
    
}

.waterMark{
    position: relative;
    height: 100%;
    z-index: 0;

  }
  
  .waterMark::before{
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:  url(../../assets/images/logo-light.png);
    background-position: center;  
    background-size: 30%;  
    background-repeat: no-repeat;
    z-index: -1;
    opacity: 0.1;
  }

  .bg-text {
    // background-color: rgb(255, 255, 255); /* Fallback color */
    // background-color: rgb(255, 255, 255); /* Black w/opacity/see-through */
    // -webkit-backdrop-filter: blur(10px);
    // backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.537);
    // background-color: rgba(255, 255, 255, 0.163);
    color: #E98B00 !important;
    font-weight: bold;
    backdrop-filter: blur(6px);
    // border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 98%;
    padding: 80px;
    text-align: center;
  }
  .click-btn{
    cursor: pointer;
    transition: all .25s ease-in-out;
  }
  .click-btn:hover{
    color:#4E949B !important;
  }